<template>
  <!-- begin::mobile version -->
  <div class="container mobile-version">
    <div class="d-flex justify-content-center">
      <div
        class="row pt-2"
        style="min-height: 500px; background-color: rgb(255, 255, 255)"
      >
        <div class="col-md-12">
          <div class="row">
            <!-- begin::page title -->
            <div class="col-12 col-md-7 pr-0">
              <h4 class="d-inline-block customPhoneH4TextSize">
                <div class="d-inline-block">
                  <a href="/" class="link-header"
                    ><img
                      src="/images/icon-football.png"
                      width="45"
                      class="p-1 customPhoneH4Icon"
                    />
                    {{ $t("football.live-score.football-live-score") }}
                  </a>
                </div>
              </h4>
            </div>
            <!-- end::page title -->
            <div class="col-12 pl-0">
              <div class="row p-0 m-0 mt-1">
                <!-- begin::hot Matches button -->
                <div
                  class="col-4 cmr-0 pr-0"
                  style="display: inline-flex; border: 0px solid rgb(0, 0, 0)"
                >
                  <div class="col-1 text-right mr-0 pr-0">
                    <div class="btn-group" style="width: 50%">
                      <span class="badge icon-top-right"> </span>
                      <div class="dropdown bootstrap-select mr-2">
                        <select
                          data-dropup-auto="false"
                          data-display="static"
                          data-style="btn-danger"
                          data-size="8"
                          data-toggle="popover"
                          data-placement="top"
                          data-container="body"
                          class="selectpicker mr-2"
                        ></select
                        ><button
                          type="button"
                          tabindex="-1"
                          class="btn dropdown-toggle bs-placeholder btn-danger"
                          data-display="static"
                          data-toggle="dropdown"
                          role="combobox"
                          aria-owns="bs-select-4"
                          aria-haspopup="listbox"
                          aria-expanded="false"
                        >
                          <div class="filter-option">
                            <div class="filter-option-inner">
                              <div class="filter-option-inner-inner">
                                {{ $t("football.live-score.hot-today") }}
                              </div>
                            </div>
                          </div>
                        </button>
                        <div class="dropdown-menu" style="overflow: hidden">
                          <div
                            class="inner show"
                            role="listbox"
                            id="bs-select-4"
                            tabindex="-1"
                            style="overflow-y: auto"
                          >
                            <ul
                              class="dropdown-menu inner show"
                              role="presentation"
                              style="margin-top: 0px; margin-bottom: 0px"
                            >
                              <li>
                                <a
                                  :href="
                                    '/' + this.$i18n.locale + '/live/football'
                                  "
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-4-0"
                                  tabindex="0"
                                  ><span class="text">
                                    {{
                                      $t("football.live-score.all-league")
                                    }}</span
                                  ></a
                                >
                              </li>

                              <li v-on:click="getHotMatches('hotMatchesTab')">
                                <a
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-3-0"
                                  tabindex="0"
                                  ><span class="text">
                                    {{
                                      $t("football.live-score.hot-matches")
                                    }}</span
                                  ></a
                                >
                              </li>

                              <li
                                v-for="hotLeagues in hotLeagues"
                                :key="hotLeagues.id"
                              >
                                <a
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-4-0"
                                  tabindex="0"
                                  ><span
                                    class="text"
                                    v-on:click="getHotLeagues(hotLeagues.id)"
                                  >
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? hotLeagues.nameCn
                                        : hotLeagues.nameEn
                                    }}</span
                                  ></a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::hot Matches button -->
                <!-- begin::Search input -->
                <div class="col-8 p-0 m-0 pl-1 text-right">
                  <div class="input-group mb-2 p-0">
                    <input
                      type="text"
                      id="inputLeagueTeamName"
                      v-model="inputLeagueTeamName"
                      :placeholder="
                        $t('football.live-score.league') +
                        ' / ' +
                        $t('football.live-score.team')
                      "
                      data-toggle="popover"
                      data-placement="top"
                      data-container="body"
                      class="form-control p-1 pl-1 customPhoneTextSize"
                      style="font-size: 80%"
                      data-original-title=""
                      title=""
                      fdprocessedid="6gpsnh"
                    />
                    <div class="input-group-append">
                      <button
                        v-on:click="searchTeamLeagueName()"
                        class="input-group-text bg-danger text-white btn p-1 customPhoneTextSize"
                      >
                        {{ $t("football.live-score.search") }}
                      </button>
                    </div>
                  </div>
                </div>
                <!-- end::Search input -->
              </div>
            </div>
          </div>

          <!-- begin::tabs -->
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                style="cursor: pointer"
                class="nav-link"
                :class="{ active: currentState == 'live' }"
                v-on:click="getMatchList(1, 'live')"
                >{{ $t("football.live-score.live") }}</a
              >
            </li>
            <li class="nav-item">
              <a
                style="cursor: pointer"
                class="nav-link"
                :class="{ active: currentState == 'upcoming' }"
                v-on:click="getMatchList(1, 'upcoming')"
                >{{ $t("state.upcoming") }}</a
              >
            </li>
            <li class="nav-item">
              <a
                style="cursor: pointer"
                class="nav-link"
                :class="{ active: currentState == 'finished' }"
                v-on:click="getMatchList(1, 'finished')"
                >{{ $t("state.finished") }}</a
              >
            </li>

            <li class="nav-item">
              <a
                v-on:click="getHotMatches('hotMatchesTab')"
                style="cursor: pointer"
                class="nav-link"
                :class="{ active: currentState == 'hotMatchesTab' }"
              >
                {{ $t("football.live-score.hot-matches") }}</a
              >
            </li>
          </ul>
          <!-- end::tabs -->

          <!-- begin::display today date -->
          <div class="row m-0 p-1 mt-1 bg-dark text-white">
            <div class="col-8 text-left" style="font-size: 16px">
              {{ currentDate() }}
            </div>
            <div class="col-4 text-right">
              <div title="Remove all on top" class="btn p-0">
                <img src="/images/icon-bin-white.png" style="width: 14px" />
              </div>
            </div>
          </div>
          <!-- end::display today date -->
          <!-- begin::matchlist -->

          <div
            class="table-zqbf row d-block"
            v-for="(match, index) in matchList"
            :key="index"
          >
            <div
              class="col-sm-12"
              v-if="
                match.league_Info &&
                match.league_Info.cn_name &&
                match.league_Info.en_name
              "
            >
              <div class="row state-live">
                <div class="col-sm-12">
                  <!-- begin::display league row -->
                  <div
                    class="row m-0 p-1"
                    style="background-color: black; color: white"
                    :style="`background-color:${match.league_Info.primary_color};`"
                  >
                    <div class="col-8 text-left text-white">
                      {{ changeTimeZone(match.match_time) }}
                      <div class="btn p-1 text-white">
                        {{
                          match.league_Info && this.$i18n.locale == "cn"
                            ? match.league_Info.cn_name
                            : match.league_Info.en_name
                        }}
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div class="btn p-0 pt-1">
                        <img
                          src="/images/icon-up-white.png"
                          style="width: 12px"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- end::display league row -->
                  <div class="row m-0 p-2 border border-bottom-0">
                    <div class="col-12">
                      <!-- begin::display home team name and logo -->
                      <span>
                        <div class="row">
                          <div class="col-12">
                            <table>
                              <tr>
                                <td
                                  style="
                                    width: 70px;
                                    position: absolute;
                                    top: 30px;
                                  "
                                >
                                  <span
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    v-if="match.status_id == 1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('state.upcoming')"
                                  >
                                    <b> {{ $t("state.upcoming-s") }}</b>
                                  </span>
                                  <span
                                    v-if="match.status_id == 2"
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('state.first-half')"
                                  >
                                    <b> {{ $t("state.first-half-s") }}</b>

                                    <b style="margin-left: 5px !important">{{
                                      onGoingMatchTime(
                                        match.counter_timing,
                                        match.status_id
                                      )
                                    }}</b>
                                    <span
                                      class="loader__dot"
                                      style="margin-left: 2px !important"
                                      >'</span
                                    >
                                  </span>
                                  <span
                                    v-else-if="match.status_id == 3"
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('state.halftime')"
                                  >
                                    <b> {{ $t("state.halftime-s") }}</b>
                                    <span
                                      class="loader__dot"
                                      style="margin-left: 3px !important"
                                      >'</span
                                    >
                                  </span>
                                  <span
                                    v-else-if="match.status_id == 4"
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('state.second-half')"
                                  >
                                    <b> {{ $t("state.second-half-s") }}</b>

                                    <b style="margin-left: 5px !important">{{
                                      onGoingMatchTime(
                                        match.counter_timing,
                                        match.status_id
                                      )
                                    }}</b>
                                    <span
                                      class="loader__dot"
                                      style="margin-left: 2px !important"
                                      >'</span
                                    >
                                  </span>
                                  <span
                                    v-else-if="match.status_id == 5"
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('state.overtime')"
                                  >
                                    <b>{{ $t("state.overtime-s") }}</b>
                                    <span
                                      class="loader__dot"
                                      style="margin-left: 3px !important"
                                      >'</span
                                    >
                                  </span>
                                  <span
                                    v-else-if="match.status_id == 7"
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('state.penalty')"
                                  >
                                    <b>{{ $t("state.penalty") }}</b>
                                    <span
                                      class="loader__dot"
                                      style="margin-left: 3px !important"
                                      >'</span
                                    >
                                  </span>

                                  <span
                                    v-else-if="match.status_id == 8"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    style="
                                      color: red;
                                      position: absolute;
                                      top: 8px;
                                    "
                                    :title="$t('state.finished')"
                                  >
                                    <b>
                                      {{ $t("state.finished-s") }}
                                    </b>
                                  </span>
                                </td>
                                <!-- test -->
                                <td>
                                  <img
                                    v-if="match.home_Info"
                                    :src="
                                      match.home_Info.logo
                                        ? match.home_Info.logo
                                        : '/images/default-football.jpg'
                                    "
                                    width="30"
                                    height="30"
                                  />
                                </td>
                                <td style="width: 200px">
                                  <span
                                    style="
                                      font-size: 1rem !important;

                                      display: -webkit-box;
                                      max-width: 400px;
                                      -webkit-line-clamp: 2;
                                      -webkit-box-orient: vertical;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                    "
                                  >
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? match.home_Info.cn_name
                                        : match.home_Info.en_name
                                    }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style="
                                      display: inline-block;
                                      padding: 0px 5px;
                                      font-size: 9px;
                                      background-color: yellow;
                                      font-size: 10px !important;
                                    "
                                  >
                                    {{
                                      match.home_Info &&
                                      match.home_Info.yellow_cards > 0
                                        ? match.home_Info.yellow_cards
                                        : ""
                                    }}
                                  </span>
                                </td>
                                <td style="width: 100px">
                                  <span
                                    style="
                                      display: inline-block;
                                      padding: 0px 3px;
                                      background-color: red;
                                      color: white;
                                      font-size: 10px !important;
                                    "
                                  >
                                    {{
                                      match.home_Info &&
                                      match.home_Info.red_cards
                                        ? match.home_Info.red_cards
                                        : ""
                                    }}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    <span
                                      style="
                                        /* margin-left: 5px !important; */
                                        font-size: 20px !important;
                                      "
                                    >
                                      <span
                                        v-if="
                                          match.status_id == 2 ||
                                          match.status_id == 3 ||
                                          match.status_id == 4 ||
                                          match.status_id == 5 ||
                                          match.status_id == 6 ||
                                          match.status_id == 7
                                        "
                                      >
                                        <b
                                          style="
                                            color: blue;
                                            font-size: 15px !important;
                                          "
                                        >
                                          {{ match.home_Info.home_score }}
                                        </b>
                                      </span>

                                      <span v-if="match.status_id == 1">
                                        <b
                                          style="
                                            color: blue;
                                            font-size: 15px !important;
                                          "
                                        >
                                          -
                                        </b>
                                      </span>

                                      <span v-if="match.status_id == 8">
                                        <b
                                          style="
                                            color: red;
                                            font-size: 15px !important;
                                          "
                                        >
                                          {{ match.home_Info.home_score }}
                                        </b>
                                      </span>
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <br />
                              <tr>
                                <td style="width: 70px"></td>
                                <td>
                                  <img
                                    v-if="match.away_Info"
                                    :src="
                                      match.away_Info.logo
                                        ? match.away_Info.logo
                                        : '/images/default-football.jpg'
                                    "
                                    width="30"
                                    height="30"
                                  />
                                </td>
                                <td style="width: 200px">
                                  <span
                                    style="
                                      font-size: 1rem !important;

                                      display: -webkit-box;
                                      max-width: 400px;
                                      -webkit-line-clamp: 2;
                                      -webkit-box-orient: vertical;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                    "
                                  >
                                    {{
                                      this.$i18n.locale == "cn"
                                        ? match.away_Info.cn_name
                                        : match.away_Info.en_name
                                    }}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style="
                                      display: inline-block;
                                      padding: 0px 5px;
                                      font-size: 9px;
                                      background-color: yellow;
                                      font-size: 10px !important;
                                    "
                                  >
                                    {{
                                      match.away_Info &&
                                      match.away_Info.yellow_cards > 0
                                        ? match.away_Info.yellow_cards
                                        : ""
                                    }}
                                  </span>
                                </td>
                                <td style="width: 100px">
                                  <span
                                    style="
                                      display: inline-block;
                                      padding: 0px 3px;
                                      background-color: red;
                                      color: white;
                                      font-size: 10px !important;
                                    "
                                  >
                                    {{
                                      match.away_Info &&
                                      match.away_Info.red_cards
                                        ? match.away_Info.red_cards
                                        : ""
                                    }}
                                  </span>
                                </td>
                                <td>
                                  <span>
                                    <span
                                      style="
                                        /* margin-left: 5px !important; */
                                        font-size: 20px !important;
                                      "
                                    >
                                      <span
                                        v-if="
                                          match.status_id == 2 ||
                                          match.status_id == 3 ||
                                          match.status_id == 4 ||
                                          match.status_id == 5 ||
                                          match.status_id == 6 ||
                                          match.status_id == 7
                                        "
                                      >
                                        <b
                                          style="
                                            color: blue;
                                            font-size: 15px !important;
                                          "
                                        >
                                          {{ match.away_Info.away_score }}
                                        </b>
                                      </span>

                                      <span v-if="match.status_id == 1">
                                        <b
                                          style="
                                            color: blue;
                                            font-size: 15px !important;
                                          "
                                        >
                                          -
                                        </b>
                                      </span>

                                      <span v-if="match.status_id == 8">
                                        <b
                                          style="
                                            color: red;
                                            font-size: 15px !important;
                                          "
                                        >
                                          {{ match.away_Info.away_score }}
                                        </b>
                                      </span>
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </span>
                      <br />

                      <span> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end::matchlist -->
          <!-- begin::loading div -->
          <center>
            <span v-if="loading">
              <p style="font-size: 0.7rem !important">
                {{ $t("football.live-score.information-is-loading") }}
                <img
                  src="/images/spinner.gif"
                  alt="spinner"
                  style="width: 12px"
                />
              </p>
            </span>
            <span v-if="matchList == ''">
              <p
                style="font-size: 0.7rem !important"
                class="bg-primary text-white"
              >
                {{ $t("basketball.live-score.temporarily-no-match") }}
              </p>
            </span>
          </center>

          <br />
        </div>
      </div>
    </div>
  </div>
  <!-- end::mobile version -->
  <!-- begin  PC Version -->
  <div class="container" style="width: 80%; min-height: 50px !important">
    <div
      class="row pt-2 pc-version"
      style="
        min-height: 400px;
        background-color: white;
        width: 91%;
        margin-left: 50px;
      "
    >
      <div class="col-md-12">
        <div class="row">
          <!-- begin::display page title -->
          <div class="col-12 col-md-7 pr-0">
            <h4 class="d-inline-block">
              <div class="d-inline-block">
                <a
                  :href="'/' + this.$i18n.locale + '/live/football'"
                  class="link-header"
                  ><img
                    src="/images/icon-football.png"
                    width="45"
                    class="p-1 customPhoneH4Icon"
                  />
                  {{ $t("football.live-score.football-live-score") }}
                </a>
              </div>
            </h4>
          </div>
          <!-- end::display page title -->
          <!-- begin::hot matches and search option -->
          <div class="col-12 col-md-5 pl-0">
            <div class="row p-0 m-0 mt-1">
              <!-- begin::hot matches -->
              <div
                class="col-7 col-md-6 mr-0 pr-0"
                style="display: inline-flex; border: 0px solid rgb(0, 0, 0)"
              >
                <div
                  class="col-12 col-md-12 justify-content-md-right text-right mr-0 pr-0"
                >
                  <div class="btn-group" style="width: 80%">
                    <div class="dropdown bootstrap-select mr-2">
                      <select
                        data-style="btn-danger"
                        data-size="8"
                        data-toggle="popover"
                        data-placement="top"
                        data-container="body"
                        class="selectpicker mr-2"
                      >
                        <option class="bs-title-option" value=""></option>
                        <option value="">
                          {{ $t("football.live-score.all-league") }}
                        </option>
                        <option
                          value="all-hot-matches"
                          v-on:click="getHotMatches('hotMatchesTab')"
                        >
                          {{ $t("football.live-score.hot-matches") }}
                        </option></select
                      ><button
                        type="button"
                        tabindex="-1"
                        class="btn dropdown-toggle bs-placeholder btn-danger"
                        data-display="static"
                        data-toggle="dropdown"
                        role="combobox"
                        aria-owns="bs-select-3"
                        aria-haspopup="listbox"
                        aria-expanded="false"
                      >
                        <div class="filter-option">
                          <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">
                              {{ $t("football.live-score.hot-today") }}
                            </div>
                          </div>
                        </div>
                      </button>
                      <div class="dropdown-menu" style="overflow: hidden">
                        <div
                          class="inner show"
                          role="listbox"
                          id="bs-select-3"
                          tabindex="-1"
                          style="overflow-y: auto"
                        >
                          <ul
                            class="dropdown-menu inner show"
                            role="presentation"
                            style="margin-top: 0px; margin-bottom: 0px"
                          >
                            <a
                              :href="'/' + this.$i18n.locale + '/live/football'"
                              style="text-decoration: none"
                            >
                              <li>
                                <a
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-3-0"
                                  tabindex="0"
                                  >{{ $t("football.live-score.all-league") }}</a
                                >
                              </li>
                            </a>
                            <li v-on:click="getHotMatches('hotMatchesTab')">
                              <a
                                role="option"
                                class="dropdown-item"
                                id="bs-select-3-0"
                                tabindex="0"
                                ><span class="text">
                                  {{
                                    $t("football.live-score.hot-matches")
                                  }}</span
                                ></a
                              >
                            </li>
                            <li
                              v-for="hotLeagues in hotLeagues"
                              :key="hotLeagues.id"
                            >
                              <a
                                role="option"
                                class="dropdown-item"
                                id="bs-select-3-1"
                                tabindex="0"
                                ><span
                                  class="text"
                                  v-on:click="getHotLeagues(hotLeagues.id)"
                                >
                                  {{
                                    this.$i18n.locale == "cn"
                                      ? hotLeagues.nameCn
                                      : hotLeagues.nameEn
                                  }}
                                </span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::hot matches -->
              <!-- begin::search option -->
              <div class="col-5 col-md-6 p-0 m-0 pl-1 text-right">
                <div class="input-group mb-2 p-0">
                  <input
                    type="text"
                    id="inputLeagueTeamName"
                    v-model="inputLeagueTeamName"
                    :placeholder="
                      $t('football.live-score.league') +
                      ' / ' +
                      $t('football.live-score.team')
                    "
                    data-toggle="popover"
                    data-placement="top"
                    data-container="body"
                    class="form-control p-1 pl-1 customPhoneTextSize"
                    style="font-size: 80%"
                    data-original-title=""
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text bg-danger text-white btn p-1 customPhoneTextSize"
                      v-on:click="searchTeamLeagueName()"
                    >
                      {{ $t("football.live-score.search") }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- end::search option -->
            </div>
          </div>
          <!-- end::hot matches and search option -->
        </div>
        <!-- Amjad Javed -->
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              style="cursor: pointer"
              class="nav-link"
              :class="{ active: currentState == 'live' }"
              v-on:click="getMatchList(1, 'live')"
              >{{ $t("football.live-score.live") }}</a
            >
          </li>
          <li class="nav-item">
            <!-- Amjad -->
            <a
              style="cursor: pointer"
              class="nav-link"
              :class="{ active: currentState == 'upcoming' }"
              v-on:click="getMatchList(1, 'upcoming')"
              >{{ $t("state.upcoming") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              style="cursor: pointer"
              class="nav-link"
              :class="{ active: currentState == 'finished' }"
              v-on:click="getMatchList(1, 'finished')"
              >{{ $t("state.finished") }}</a
            >
          </li>

          <li class="nav-item">
            <a
              v-on:click="getHotMatches('hotMatchesTab')"
              style="cursor: pointer"
              class="nav-link"
              :class="{ active: currentState == 'hotMatchesTab' }"
            >
              {{ $t("football.live-score.hot-matches") }}</a
            >
          </li>
        </ul>
        <!-- end::loading -->
        <!-- begin::matchlist -->
        <hr />
        <template v-if="loading || moreMatches">
          <p style="position: absolute; top: 10px; left: 290px">
            {{
              moreMatches
                ? $t("football.live-score.loading-more-matches")
                : $t("basketball.live-score.information-is-loading")
            }}
            <img src="/images/spinner.gif" alt="spinner" style="width: 20px" />
          </p>
        </template>
        <table
          class="table table-sm table-bordered table-hover text-center"
          style="margin-top: -20px !important"
          id="live_table"
        >
          <!-- begin::header -->
          <thead class="bg-dark text-white">
            <tr style="height: 10px !important">
              <th style="width: 96px !important">
                {{ currentDate() }}
              </th>
              <th>{{ $t("football.live-score.time") }}</th>
              <th>{{ $t("football.live-score.status") }}</th>
              <th>{{ $t("football.live-score.home-team") }}</th>
              <th>{{ $t("football.live-score.score") }}</th>
              <th>{{ $t("football.live-score.away-team") }}</th>
              <th>{{ $t("football.live-score.ht") }}</th>
              <th>{{ $t("football.live-score.index") }}</th>
              <th>{{ $t("football.live-score.game-data-analysis") }}</th>
              <th>
                <div title="Remove all on top" class="btn p-0">
                  <img src="/images/icon-bin-white.png" style="width: 12px" />
                </div>
              </th>
            </tr>
          </thead>
          <!-- end::header -->
          <tbody v-for="(match, index) in matchList" :key="index">
            <tr
              v-if="
                match.league_Info &&
                match.league_Info.en_name
                //match.league_Info.cn_name &&
              "
            >
              <td
                class="align-middle text-black"
                style="background-color: black; color: white"
                :style="`background-color:${match.league_Info.primary_color};`"
              >
                <div
                  class="p-0 align-middle"
                  style="font-size: 10px; cursor: pointer"
                >
                  <span
                    data-toggle="modal"
                    data-target="#leaguePopup"
                    v-on:click="getLeagueDetails(match.competition_id)"
                  >
                    <b>
                      {{
                        match.league_Info && this.$i18n.locale == "cn"
                          ? ( (match.league_Info.cn_name!="" ? match.league_Info.cn_name : match.league_Info.en_name ) )
                          : match.league_Info.en_name
                      }}
                    </b>
                  </span>
                </div>
              </td>
              <td class="align-middle" style="font-size: 12px">
          
                {{ changeTimeZone(match.match_time) }}
              </td>
              <td class="align-middle" style="font-size: 10px">
                <!-- begin::match status -->
                <span>
                  <span
                    v-if="match.status_id == 1"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('state.upcoming')"
                  >
                    {{ $t("state.upcoming-s") }}
                  </span>
                  <span
                    v-if="match.status_id == 2"
                    style="color: red"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('state.first-half')"
                  >
                    <b> {{ $t("state.first-half-s") }}</b>
                    <b style="margin-left: 2px !important">{{
                      onGoingMatchTime(match.counter_timing, match.status_id)
                    }}</b>
                    <span
                      class="loader__dot"
                      style="margin-left: 2px !important"
                      >'</span
                    >
                  </span>
                  <span
                    v-else-if="match.status_id == 3"
                    style="color: red"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('state.halftime')"
                  >
                    <b> {{ $t("state.halftime-s") }}</b>
                    <span
                      class="loader__dot"
                      style="margin-left: 3px !important"
                      >'</span
                    >
                  </span>
                  <span
                    v-else-if="match.status_id == 4"
                    style="color: red"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('state.second-half')"
                  >
                    <b> {{ $t("state.second-half-s") }}</b>
                    <b style="margin-left: 2px !important">{{
                      onGoingMatchTime(match.counter_timing, match.status_id)
                    }}</b>
                    <span
                      class="loader__dot"
                      style="margin-left: 2px !important"
                      >'</span
                    >
                  </span>
                  <span
                    v-else-if="match.status_id == 5"
                    style="color: red"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('state.overtime')"
                  >
                    <b>{{ $t("state.overtime-s") }}</b>
                    <span
                      class="loader__dot"
                      style="margin-left: 3px !important"
                      >'</span
                    >
                  </span>
                  <span
                    v-else-if="match.status_id == 7"
                    style="color: red"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="$t('state.penalty')"
                  >
                    <b>{{ $t("state.penalty") }}</b>
                    <span
                      class="loader__dot"
                      style="margin-left: 3px !important"
                      >'</span
                    >
                  </span>

                  <span
                    v-else-if="match.status_id == 8"
                    data-toggle="tooltip"
                    data-placement="top"
                    style="color: red"
                    :title="$t('state.finished')"
                  >
                    <b>
                      {{ $t("state.finished-s") }}
                    </b>
                  </span>
                </span>
                <!-- end::match status -->
              </td>

              <td class="text-right align-middle">
                <div
                  class="p-0"
                  style="
                    display: inline-block;
                    font-size: 12px;
                    cursor: pointer;
                  "
                  data-toggle="modal"
                  data-target="#teamInformationPopup"
                >
                  <div>
                    <span
                      style="
                        display: inline-block;
                        padding: 0 1px;
                        font-size: 9px;
                        background-color: red;
                        color: white;
                      "
                    >
                      {{
                        match.home_Info && match.home_Info.red_cards > 0
                          ? match.home_Info.red_cards
                          : ""
                      }}
                    </span>

                    <span
                      style="
                        display: inline-block;
                        padding: 0 1px;
                        font-size: 9px;
                        background-color: yellow;
                      "
                    >
                      {{
                        match.home_Info && match.home_Info.yellow_cards > 0
                          ? match.home_Info.yellow_cards
                          : ""
                      }}
                    </span>

                    {{
                      this.$i18n.locale == "cn"
                        ? ( (match.home_Info.cn_name!="" ? match.home_Info.cn_name : match.home_Info.en_name ) )
                        : match.home_Info.en_name
                    }}
                    <img
                      :src="
                        match.home_Info.logo
                          ? match.home_Info.logo
                          : '/images/default-football.jpg'
                      "
                      style="width: 20px !important"
                      v-on:click="
                        getTeamDetails(match.id, match.home_team_id, 'home')
                      "
                    />
                  </div>
                </div>
              </td>

              <td
                id="popover-target-score-2320651-1"
                class="align-middle"
                style="cursor: pointer; font-size: 12px !important"
              >
                <span
                  v-if="
                    match.status_id == 2 ||
                    match.status_id == 3 ||
                    match.status_id == 4 ||
                    match.status_id == 5 ||
                    match.status_id == 6 ||
                    match.status_id == 7
                  "
                >
                  <b style="color: blue; font-size: 15px !important">
                    {{
                      match.home_Info.home_score +
                      "-" +
                      match.away_Info.away_score
                    }}
                  </b>
                </span>

                <span v-if="match.status_id == 1">
                  <b style="color: blue; font-size: 15px !important"> - </b>
                </span>

                <span v-if="match.status_id == 8">
                  <b style="color: red; font-size: 15px !important">
                    {{
                      match.home_Info.home_score +
                      "-" +
                      match.away_Info.away_score
                    }}
                  </b>
                </span>
              </td>
              <td class="text-left align-middle">
                <div
                  class="p-0"
                  style="
                    display: inline-block;
                    font-size: 12px;
                    cursor: pointer;
                  "
                  data-toggle="modal"
                  data-target="#teamInformationPopup"
                >
                  <img
                    :src="
                      match.away_Info.logo
                        ? match.away_Info.logo
                        : '/images/default-football.jpg'
                    "
                    style="width: 20px !important"
                    v-on:click="
                      getTeamDetails(match.id, match.away_team_id, 'away')
                    "
                  />

                  <span>
                    {{
                      this.$i18n.locale == "cn"
                        ? ( (match.away_Info.cn_name!="" ? match.away_Info.cn_name : match.away_Info.en_name ) )
                        : match.away_Info.en_name
                    }}

                    <span
                      style="
                        display: inline-block;
                        padding: 0 1px;
                        font-size: 9px;
                        background-color: yellow;
                      "
                    >
                      {{
                        match.away_Info && match.away_Info.yellow_cards > 0
                          ? match.away_Info.yellow_cards
                          : ""
                      }}
                    </span>

                    <span
                      style="
                        display: inline-block;
                        padding: 0 1px;
                        font-size: 9px;
                        background-color: red;
                        color: white;
                      "
                    >
                      {{
                        match.away_Info && match.away_Info.red_cards > 0
                          ? match.away_Info.red_cards
                          : ""
                      }}
                    </span>
                  </span>
                </div>
              </td>
              <td
                class="align-middle"
                style="font-size: 12px; color: red; width: 30px !important"
              >
                <div>
                  <span
                    v-if="matchStatus == 'finished' || matchStatus == 'live'"
                  >
                    {{
                      match.home_Info.half_time_score +
                      "-" +
                      match.away_Info.half_time_score
                    }}
                  </span>

                  <span v-if="matchStatus == 'upcoming'"><b>- </b></span>
                </div>
              </td>
              <!-- begin::odds column -->
              <td
                class="table-borderless align-middle text-center"
                style="text-align: center; color: brown"
              >
                <Popper class="light" arrow hover="true">
                  <center>
                    <table
                      style="
                        background-color: transparent;
                        font-size: 10px !important;
                      "
                    >
                      <tr style="background-color: transparent">
                        <td>
                          <span
                            v-if="
                              match.odds &&
                              match.odds.init &&
                              match.odds.init.asia
                            "
                          >
                            {{ match.odds.init.asia.home }}
                          </span>
                          <span v-else> &nbsp;&nbsp;&nbsp;&nbsp;- </span>
                        </td>

                        <td></td>

                        <td>
                          <span
                            v-if="
                              match.odds &&
                              match.odds.init &&
                              match.odds.init.asia
                            "
                          >
                            {{ match.odds.init.asia.handicap }}
                          </span>
                          <span v-else> &nbsp;&nbsp;&nbsp;&nbsp;- </span>
                        </td>
                        <td></td>
                        <td>
                          <span
                            v-if="
                              match.odds &&
                              match.odds.init &&
                              match.odds.init.asia
                            "
                          >
                            {{ match.odds.init.asia.away }}
                          </span>
                          <span v-else> &nbsp;&nbsp;&nbsp;&nbsp;- </span>
                        </td>
                      </tr>
                      <tr style="background-color: transparent">
                        <td>
                          <span
                            v-if="
                              match.odds &&
                              match.odds.init &&
                              match.odds.init.bigSmall
                            "
                          >
                            {{ match.odds.init.bigSmall.home }}
                          </span>
                          <span v-else> &nbsp;&nbsp;&nbsp;&nbsp;- </span>
                        </td>

                        <td></td>

                        <td>
                          <span
                            v-if="
                              match.odds &&
                              match.odds.init &&
                              match.odds.init.bigSmall
                            "
                          >
                            {{ match.odds.init.bigSmall.handicap }}
                          </span>
                          <span v-else> &nbsp;&nbsp;&nbsp;&nbsp;- </span>
                        </td>
                        <td></td>
                        <td>
                          <span
                            v-if="
                              match.odds &&
                              match.odds.init &&
                              match.odds.init.bigSmall
                            "
                          >
                            {{ match.odds.init.bigSmall.away }}
                          </span>
                          <span v-else> &nbsp;&nbsp;&nbsp;&nbsp;- </span>
                        </td>
                      </tr>
                    </table>
                  </center>
                  <template #content v-if="match.odds">
                    <div style="width: 550px !important">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr style="background-color: rgb(245, 245, 245)">
                            <td
                              colspan="8"
                              style="color: black; font-size: 16px !important"
                              class="text-left"
                            >
                              <b style="font-size: 16px !important">
                                &nbsp; {{ $t("odds.index") }}</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td style="background-color: #edd713" colspan="8">
                              {{ $t("odds.full") }}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              class="text-left pl-4 text-white"
                              style="background-color: black; color: white"
                              :style="`background-color:${match.league_Info.primary_color};`"
                            >
                              <span>
                                {{
                                  match.league_Info && this.$i18n.locale == "cn"
                                    ? match.league_Info.cn_name
                                    : match.league_Info.en_name
                                }}
                              </span>
                            </td>

                            <td
                              class="odds-table-match-title text-center"
                              colspan="7"
                            >
                              <span>
                                {{
                                  match.home_Info && this.$i18n.locale == "cn"
                                    ? match.home_Info.cn_name
                                    : match.home_Info.en_name
                                }}
                              </span>
                              <span
                                style="font-size: 16px !important; color: red"
                                >&nbsp;
                                {{ match.home_Info.home_score }}
                                -
                                {{ match.away_Info.away_score }}&nbsp;
                              </span>

                              <span>
                                {{
                                  match.away_Info && this.$i18n.locale == "cn"
                                    ? match.away_Info.cn_name
                                    : match.away_Info.en_name
                                }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="odds-type">
                              <span style="color: yellow" rowspan="2"></span>
                            </td>
                            <td
                              class="text-center"
                              style="font-size: 16px !important"
                            ></td>
                            <td
                              colspan="3"
                              class="odds-table-live-title text-center"
                              style="font-size: 16px !important"
                            >
                              {{ $t("odds.live") }}
                            </td>
                            <td
                              colspan="3"
                              class="odds-table-title text-center"
                              style="font-size: 16px !important"
                            >
                              {{ $t("odds.init") }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="odds-type"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td></td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.home") }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.away") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.home") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.away") }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="odds-type"
                              style="font-size: 15px !important"
                            ></td>
                            <td></td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                                  ? match.odds.init.asia.home
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                                  ? match.odds.init.asia.handicap
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                                  ? match.odds.init.asia.away
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                                  ? (match.odds.init.asia.home + 0.5).toFixed(1)
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                                  ? (
                                      match.odds.init.asia.handicap + 0.5
                                    ).toFixed(1)
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.asia
                                  ? (match.odds.init.asia.away + 0.5).toFixed(2)
                                  : "-"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="odds-type"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.over-under") }}
                            </td>
                            <td></td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.small") }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.big") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.small") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.big") }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="odds-type"
                              style="font-size: 15px !important"
                            ></td>
                            <td></td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                                  ? match.odds.init.bigSmall.home
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                                  ? match.odds.init.bigSmall.handicap
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                                  ? match.odds.init.bigSmall.away
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                                  ? (
                                      match.odds.init.bigSmall.home + 0.5
                                    ).toFixed(2)
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                                  ? (
                                      match.odds.init.bigSmall.handicap + 0.5
                                    ).toFixed(1)
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.bigSmall
                                  ? (
                                      match.odds.init.bigSmall.away + 0.5
                                    ).toFixed(1)
                                  : "-"
                              }}
                            </td>
                          </tr>

                          <tr>
                            <td
                              class="odds-type"
                              style="
                                font-size: 15px !important;
                                width: 150px !important;
                              "
                            >
                              {{ $t("odds.euro") }}
                            </td>
                            <td></td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.small") }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.big") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.small") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.handicap") }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{ $t("odds.big") }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="odds-type"
                              style="font-size: 15px !important"
                            ></td>
                            <td></td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                                  ? match.odds.init.euro.home
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                                  ? match.odds.init.euro.handicap
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-live-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                                  ? match.odds.init.euro.away
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                                  ? (match.odds.init.euro.home + 0.5).toFixed(1)
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                                  ? (
                                      match.odds.init.euro.handicap + 0.5
                                    ).toFixed(1)
                                  : "-"
                              }}
                            </td>
                            <td
                              class="odds-table-title"
                              style="font-size: 15px !important"
                            >
                              {{
                                match.odds &&
                                match.odds.init &&
                                match.odds.init.euro
                                  ? (match.odds.init.euro.away + 0.5).toFixed(2)
                                  : "-"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </Popper>
              </td>
              <!-- end::odds column -->
              <td
                style="width: 180px !important"
                class="align-middle text-left"
              >
                <div class="col-md-12 pl-1 m-0">
                  <button
                    v-if="match.odds"
                    class="btn btn-outline-primary btn-sm p-1"
                    style="font-size: 11px"
                    v-on:click="getMatchOdds(match.id, match)"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    {{ $t("football.live-score.index") }}
                  </button>
                  &nbsp;

                  <button
                    class="btn btn-outline-primary btn-sm p-1"
                    style="font-size: 11px"
                    data-toggle="modal"
                    data-target="#matchStatics"
                    v-on:click="getMatchStatics(match.id)"
                  >
                    {{ $t("football.h2h.h2h") }}
                  </button>
                </div>
              </td>
              <td class="align-middle">
                <div title="Mark to top" class="btn p-0">
                  <img src="/images/icon-up.png" style="width: 12px" />
                </div>
              </td>
            </tr>
          </tbody>

          <!--begin::dev  loading more matches-->
          <tbody
            v-if="matchList == '' || moreMatches"
            style="background-color: white"
          >
            <tr>
              <td colspan="14">
                <div
                  class="text-white p-2"
                  style="background-color: rgb(52, 144, 220)"
                >
                  {{
                    matchList == ""
                      ? $t("basketball.live-score.temporarily-no-match")
                      : this.currentPage < this.pagesCnt
                      ? $t("football.live-score.loading-more-matches")
                      : $t("basketball.live-score.no-more-matches")
                  }}
                </div>
              </td>
            </tr>
          </tbody>
          <!--end::dev loading more matches -->
        </table>
        <!-- end::matchlist -->
      </div>
    </div>
    <!-- end::PC Version code -->
  </div>
  <!-- begin::league Information popup -->
  <LeagueDetailsModal :leagueId="leagueId" :type="'football'" />
  <!-- end::league information popup -->
  <!-- begin::team Information popup -->
  <TeamDetailsModal
    :findTeam="findTeam"
    :teamMatchId="teamMatchId"
    :teamId="teamId"
    :type="'football'"
  />
  <!-- end::team Information popup  -->

  <MatchOddsModal
    :matchId="matchId"
    :matchDetails="matchDetails"
    :type="'football'"
  />

  <MatchStatistics :matchStaticId="matchStaticId" :type="'football'" />
  <br />
</template>
<!-- begin::scripts -->
<script>

import LeagueDetailsModal from "./../modals/league-details-modal.vue";
import TeamDetailsModal from "../modals/modal-team-details.vue";
import MatchOddsModal from "./../modals/match-odds-modal.vue";
import MatchStatistics from "./../modals/match-statistics.vue";
import Popper from "vue3-popper";
export default {
  components: {

    LeagueDetailsModal,
    MatchOddsModal,
    TeamDetailsModal,
    Popper,
    MatchStatistics,
  },
  data() {
    return {
      matchList: null,
      hotLeagues: [],
      loading: false,
      currentPage: 1,
      language: "english",
      inputLeagueTeamName: "",
      leagueId: null,
      teamId: null,
      pagesCnt: 1,
      hotMatches: false,
      moreMatches: false,
      matchStatus: "live",
      matchState: [2, 3, 4, 5, 6, 7],
      hotMatchesTab: "",
      matchId: null,
      matchDetails: null,
      teamMatchId: null,
      findTeam: null,
      matchStaticId: null,
      currentState: "live",
    };
  },
  mounted() {
    window.$('[data-toggle="tooltip"]').tooltip();
  },
  created() {
    this.getMatchList(1, this.matchStatus);
    // on scroll call display more matches
    window.addEventListener("scroll", () => {
      // if hotMatches Tab is Selected
      if (this.hotMatches) {
        this.loading = false;
        return "";
      }
      // if live, upcoming or finished Tab is Selected
      else {
        if (this.pagesCnt >= this.currentPage) {
          this.moreMatches = true;
          this.getMatchList(this.currentPage, this.matchStatus);
        } else {
          this.moreMatches = false;
          return "";
        }
      }
    });
    // refresh frontend every 30 seconds
    setInterval(() => {
      if (this.hotMatches) {
        this.loading = false;
        return "";
      }
      this.currentPage > 1 ? (this.loading = false) : (this.loading = true);
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/today?matchStatus=${this.matchState}&pageNumber=1`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
          this.pagesCnt = response.data.totalPages;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 30000);
  },

  methods: {
    // method to get the current Date
    currentDate() {
      const moment = require("moment-timezone");

      let timezone,
        todayDatetime = null;
      if (this.$i18n.locale == "en") {
        timezone = "America/New_York";
      } else if (this.$i18n.locale == "ty") {
        timezone = "Asia/Jakarta";
      } else if (this.$i18n.locale == "vi") {
        timezone = "Asia/Vientiane";
      } else if (this.$i18n.locale == "th") {
        timezone = "Asia/Bangkok";
      } else {
        timezone = "Asia/Shanghai";
      }

      todayDatetime = moment(new Date(), "YYYY/MM/DD hh:mm:ss").tz(
        `${timezone}`
      );
      return moment(todayDatetime).format("YYYY/MM/DD");
    },

    changeTimeZone(matchTime) {
      const moment = require("moment-timezone");
      var date = new Date(matchTime * 1000);

      if (this.$i18n.locale == "en") {
        return moment(date).tz("America/New_York").format("HH:mm");
      } else if (this.$i18n.locale == "ty") {
        return moment(date).tz("Asia/Jakarta").format("HH:mm");
      } else if (this.$i18n.locale == "vi") {
        return moment(date).tz("Asia/Vientiane").format("HH:mm");
      } else if (this.$i18n.locale == "th") {
        return moment(date).tz("Asia/Bangkok").format("HH:mm");
      } else {
        return moment(date).tz("Asia/Shanghai").format("HH:mm");
      }
    },

    // method to get the matches of hotLeagues
    getHotLeagues(id) {
      this.hotMatches = true;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/today?hotLeagueId=${id}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //  method to get the matchList
    getMatchList(pageNumber, state) {
      if (this.loading) return;

      this.loading = true;
      let uri = `${this.BASE_SERVER_URI}/api6/football/matchlist/today?matchStatus=${state}&pageNumber=${pageNumber}`;
      this.$axios
        .get(uri)
        .then((response) => {
          this.matchStatus = state;
          this.currentState = state;
          this.pagesCnt = response.data.totalPages;
          this.currentPage = pageNumber + 1;
          this.matchList = response.data.matchList;
          this.hotLeagues = response.data.hotLeagues;
          this.loading = false;
          this.moreMatches = false;
          this.hotMatches = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get the match which is searched
    searchTeamLeagueName() {
      let inputkeyword = this.inputLeagueTeamName;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/today?pageNumber=1&locale=${this.$i18n.locale}&inputLeagueTeamName=${inputkeyword}`
        )
        .then((response) => {
          this.matchList = response.data.matchList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // method to get match odds
    getMatchOdds(matchId, match) {
      this.matchId = matchId;
      this.matchDetails = match;
    },
    getLeagueDetails(id) {
      this.leagueId = id;
    },

    changeLanguage(language) {
      this.language = language;
    },
    getMatchStatics(matchId) {
      this.matchStaticId = matchId;
    },

    getTeamDetails($matchId, teamId, team) {
      this.teamId = teamId;
      this.teamMatchId = $matchId;
      this.findTeam = team;
    },

    getHotMatches(state) {
      this.hotMatches = true;
      this.currentState = state;
      this.$axios
        .get(
          `${this.BASE_SERVER_URI}/api6/football/matchlist/today?hotMatches=true`
        )
        .then((response) => {
          this.matchList = response.data.hotMatches;
        })
        .catch((error) => {
          console.log(error);
        }); // code to run on error
    },

    // method to calculate the time for live match
    onGoingMatchTime(matchTime, status_code) {
      let differenceTime = new Date() - new Date(matchTime * 1000);

      var minutes = Math.floor(differenceTime / 60000);
      if (status_code == 2) {
        if (minutes < 45) {
          return minutes;
        } else {
          return "45+";
        }
      }
      if (status_code == 4) {
        if (45 + minutes < 90) {
          return 45 + minutes;
        } else {
          return "90+";
        }
      }
      return minutes;
    },
  },
};
</script>

<style scoped>
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
  .pc-version {
    display: none;
  }

  .mobile-version {
    display: block;
  }
}

/* Tablets, iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Tablets, iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .pc-version {
    display: block;
  }

  .mobile-version {
    display: none;
  }
}

@keyframes blink {
  100% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.tooltip .arrow {
  display: none !important;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  49% {
    color: red;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: red;
  }
}

tbody:first-child tr {
  background: none;
}

tbody:nth-child(even) tr {
  background-color: rgba(0, 0, 0, 0.05);
}

tbody:nth-child(odd) tr {
  background: #fff;
}

tbody tr:hover {
  background: #ececec;
}

@media (max-width: 400px) {
}

.table-sm th,
.table-sm td {
  padding: 0.1rem;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 0px;
}

.player-detail-list {
  padding: 0;
  margin: 0;
  border: solid 0px #000;
}

.player-detail-list label {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}

.player-detail-list div {
  padding: 0;
  margin: 0;
  padding: 2px 4px 2px 4px;
}

.divider {
  height: 30px;
  background-color: #f7be57;
  color: white;
}

.league-title {
  color: white;
}

.odds-table-company-title {
  background-color: white;
}

.odds-table-match-title {
  background-color: lightyellow;
}

.odds-table-live-title {
  background-color: aliceblue;
}

.odds-table-title {
  background-color: lightgrey;
}

.odds-type {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
}

.league-title {
  color: white;
}

.odds-table-company-title {
  background-color: white;
}

.odds-table-match-title {
  background-color: lightyellow;
}

.popover {
  width: 750px !important;
  max-width: 1000px !important;
}
</style>

